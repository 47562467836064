// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  type: 'PRODUCTION',
  appVersion: require('../../package.json').version,
  production: true,
  idleTime: 600,
  timeout: 30,
  base_eru_routes_url: 'https://erufunc.devapi.artfine.in/',
  base_eru_url: 'https://eruql.devapi.artfine.in/',
  base_eru_auth_url: 'https://eruauth.devapi.artfine.in/',
  base_eru_files_url: 'https://erufiles.devapi.artfine.in/',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
